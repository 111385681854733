<template>
  <el-form
    ref="nodeForm"
    :rules="rules"
    label-position="top"
    :model="nodeToBind"
    :hide-required-asterisk="false"
    style="width: 100%"
    class="link-task-node"
  >
    <el-row type="flex">
      <el-col :span="24">
        <el-form-item prop="node_name" :label="__('Name')">
          <el-input v-model="nodeToBind.node_name" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" class="link-task-target">
      <el-col :span="24">
        <el-form-item
          :label="__('Goto Task')"
          style="width: 100%"
          prop="link_task"
          class="is-required"
        >
          <el-select
            v-model="nodeToBind.link_task_node.data.task_id"
            :placeholder="__('Select Task')"
            style="width: 100%"
            v-loading="tasksLoading || isChecking"
            default-first-option
            filterable
            clearable
          >
            <el-option
              v-for="task in getTargetTasks"
              :key="task.task_id"
              :label="task.task_name"
              :value="task.task_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <div class="link-task-target-recursion" v-if="possibleRecursion">
        <div class="warning-exclamation" />
        <div style="margin-left: 5px; display: flex">
          <div>
            {{
              __("Choosing this task as the goto task may lead to recursion")
            }}.
          </div>
        </div>
      </div>
    </el-row>
  </el-form>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapState } from "vuex";
import { validateLinkTaskIdForRecursion } from "@/api/nodes";

export default {
  mixins: [BaseNode],
  data() {
    let validateTaskIdForRecursion = async (rule, value, callback) => {
      try {
        if (
          !_.isEmpty(this.nodeToBind.link_task_node.data.task_id + "") &&
          this.nodeToBind.link_task_node.data.task_id > 0
        ) {
          this.possibleRecursion = false;
          this.isChecking = true;
          this.toggleValidationsInProgress(true);
          const res = await validateLinkTaskIdForRecursion(
            this.nodeToBind.task_id,
            this.nodeToBind.link_task_node.data.task_id
          );
          this.isChecking = false;
          this.toggleValidationsInProgress(false);
          if (res.data.recursion_found) {
            this.possibleRecursion = true;
            // callback(new Error("Can't link to this task recursion detected"));
            callback();
          } else {
            this.possibleRecursion = false;
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };
    const validateLinkTask = (rule, value, callback) => {
      if (_.isEmpty(this.nodeToBind.link_task_node.data.task_id + "")) {
        callback(__("Linked task cannot be empty"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        link_task: [
          {
            validator: validateLinkTask,
            trigger: "change"
          },
          { validator: validateTaskIdForRecursion, trigger: "change" }
        ]
      },
      possibleRecursion: false,
      isChecking: false
    };
  },
  computed: {
    ...mapState("tasks", {
      tasks: state => state.tasks,
      tasksLoading: state => state.loading
    }),
    getTargetTasks() {
      let [[currentTask], otherTasks] = _.partition(this.tasks, [
        "task_id",
        this.task_id
      ]);
      if (
        typeof currentTask === "object" &&
        Object.hasOwn(currentTask, "is_utility") &&
        currentTask.is_utility
      ) {
        return _.filter(otherTasks, "is_utility");
      }
      return otherTasks;
    }
  },
  methods: {
    ...mapActions("tasks", {
      getTasks: "getTasks"
    }),
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.link_task_node)) {
      this.$set(this.nodeToBind, "link_task_node", {});
      this.$set(this.nodeToBind.link_task_node, "data", {});
      this.$set(this.nodeToBind.link_task_node.data, "task_id", "");
    }
    this.$set(this.nodeToBind, "node_type", NODE_TYPES.LINK_TASK.NODE_TYPE);
    this.getTasks({ notShowLoader: false, fetch_all: 1 });
  },
  watch: {
    isEditing: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.nodeForm.validate();
          });
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/node_common.scss";

.link-task-node {
  .link-task-target {
    display: flex;
    flex-direction: column;
    color: #696969;

    .link-task-target-recursion {
      display: flex;
      align-items: center;
      margin-top: -30px;
      color: currentColor;
      line-height: 1.5;
      font-size: 0.8125rem;
      letter-spacing: 0.005rem;
    }
  }
}
</style>
